import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

const SwimmingPool = () => {
  const {
    file: { childImageSharp },
  } = useStaticQuery(graphql`
    query SwimmingPoolQuery {
      file(name: { eq: "thomas-park-QGdRrty4054-unsplash" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img fluid={childImageSharp.fluid} className="h-full" alt="Une piscine" />
  );
};

export default SwimmingPool;
