import React from 'react';
import AlertError from 'src/components/design-system/alerts/error';
import Logo from 'src/components/logo';
import ActionButton from 'src/components/design-system/buttons/action';
import useForm, { updateTextField } from 'src/hooks/useForm';
import { isLoading } from 'src/hooks/axios/useAxios';
import SimpleInput from 'src/components/design-system/form/input';
import routes from 'src/config/routes';

const Activate = () => {
  const {
    formData,
    setFormData,
    isFormPristine,
    requestState,
    submitForm,
  } = useForm({
    initialValue: {},
    axiosConfig: {
      method: 'post',
      url: routes.activate(),
    },
    formToPayload: (i) => i,
    redirectUrl: '/login',
  });

  const updateField = updateTextField(setFormData, formData);

  const errors =
    requestState.status === 'error' && requestState.error
      ? requestState.error.errors
      : {};

  return (
    <div>
      <div className="">
        <Logo className="m-auto h-36" />
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900 leading-9">
          Activation de votre compte
        </h2>
        <p className="mt-2 text-sm text-gray-600 leading-5 max-w"></p>
      </div>
      {requestState.status === 'error' && !requestState.error && (
        <AlertError message="Une erreur est survenue, merci de réessayer ultérieurement" />
      )}

      <div className="">
        <div className="mt-4">
          <form action="#" method="POST" onSubmit={submitForm}>
            <div>
              <label
                for="email"
                className="block text-sm font-medium text-gray-700 leading-5"
              >
                Adresse email
              </label>
              <SimpleInput
                type="email"
                id="email"
                onChange={updateField('email')}
                errors={errors}
                className="mt-1"
                required
              />
            </div>

            <div className="mt-6">
              <label
                for="password"
                className="block text-sm font-medium text-gray-700 leading-5"
              >
                Mot de passe
              </label>
              <SimpleInput
                type="password"
                id="password"
                onChange={updateField('password')}
                errors={errors}
                required
                className="mt-1"
              />
            </div>

            <div className="mt-6">
              <label
                for="password_confirmation"
                className="block text-sm font-medium text-gray-700 leading-5"
              >
                Confirmer votre mot de passe
              </label>
              <SimpleInput
                type="password"
                id="password_confirmation"
                onChange={updateField('password_confirmation')}
                errors={errors}
                className="mt-1"
                required
              />
            </div>

            <ActionButton
              className="justify-center w-full mt-6"
              type="submit"
              disabled={isFormPristine}
              isLoading={isLoading(requestState)}
            >
              Créez votre compte
            </ActionButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Activate;
