import React from 'react';

import SwimmingPool from './login/pool';
import ContentNav from './content/nav';

const LoginLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <ContentNav />
      <div className="flex-grow bg-white relative">
        <div className=" absolute z-10 flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 w-full">
          <div className="w-full max-w-sm mx-auto">{children}</div>
        </div>
        <div className="absolute z-0 flex-1 w-0 block top-0 left-0 w-full">
          <SwimmingPool />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
