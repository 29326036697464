import React from 'react';
import LoginLayout from 'src/components/layouts/login';
import Activate from 'src/components/activate';
import SEO from 'src/components/seo';
import Card from 'src/components/design-system/card';

const LoginPage = () => {
  return (
    <LoginLayout>
      <SEO title="Activez votre compte" />
      <Card className="max-w-2xl">
        <Activate />
      </Card>
    </LoginLayout>
  );
};

export default LoginPage;
